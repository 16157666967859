import { useCallback, useEffect, useState } from 'react';
import { useApplicationContext } from '../ApplicationContext';
import { FrequencyOption, DayOption, TIME_OPTIONS, TimeZoneOption, DAY_OF_MONTH_OPTIONS } from '../Components/Subscriptions/subscriptionConstants';
import { DayOfMonthOption, ParseSubscriptionScheduleResult } from '../Components/Subscriptions/subscriptionTypes';
import { createSubscriptionSchedules, parseSubscriptionSchedules } from '../Components/Subscriptions/subscriptionsUtil';
import { UserReportSubscriptionSchedule } from '../lib/types';

const clone = require('rfdc')();

const useSubscriptionEditor = (props: any) => {
    const { selectedCompanyId } = useApplicationContext();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
    const [frequency, setFrequency] = useState<FrequencyOption>(FrequencyOption.DAILY);
    const [daysOfMonth, setDaysOfMonth] = useState<DayOfMonthOption[]>([]);
    const [days, setDays] = useState<DayOption[]>([]);
    const [time, setTime] = useState<number>(TIME_OPTIONS[0].value);
    const [timeZone, setTimeZone] = useState<TimeZoneOption>(TimeZoneOption.EST);
    const [userCommunities, setUserCommunities] = useState([]);
    const [subscriptionCommunities, setSubscriptionCommunities] = useState<any[]>([]);
    const [parsedSubscription, setParsedSubscription] = useState<ParseSubscriptionScheduleResult>(
        props.currentSubscription ? parseSubscriptionSchedules(props.currentSubscription?.userReportSubscriptionSchedules) : null,
    );
    const setFrequencyFromEnum = (f: FrequencyOption) => {
        if (f === FrequencyOption.WEEKLY) {
            setDaysOfMonth([]);
        } else if (f === FrequencyOption.DAILY) {
            setDays([]);
            setDaysOfMonth([]);
        }
        setFrequency(f);
    };

    const closeDialog = () => {
        setShowDeleteConfirmation(false);
        props.setShowDialog(false);
        resetSubscription();
    };

    const toggleDeleteConfirmation = () => {
        setShowDeleteConfirmation(!showDeleteConfirmation);
    };

    const resetSubscription = useCallback(() => {
        if (parsedSubscription) {
            setFrequency(parsedSubscription.frequency);
            setDaysOfMonth(parsedSubscription.daysOfMonth.map((v) => DAY_OF_MONTH_OPTIONS.find((d) => d.value === v)));
            setDays(parsedSubscription.days);
            setTime(parsedSubscription.time);
            setTimeZone(parsedSubscription.timeZone ?? TimeZoneOption.EST);
            setSubscriptionCommunities(props.currentSubscription?.communities?.map((c) => ({ name: c.name, id: c.id })) ?? []);
        } else {
            setDays([]);
            setDaysOfMonth([]);
            setTime(TIME_OPTIONS[0].value);
            setTimeZone(TimeZoneOption.EST);
            setFrequency(FrequencyOption.DAILY);
            setSubscriptionCommunities(userCommunities);
        }
    }, [parsedSubscription, props.currentSubscription?.communities, userCommunities]);

    const createSubscription = useCallback(() => {
        let userReportSubscriptionSchedules: UserReportSubscriptionSchedule[] = createSubscriptionSchedules(
            daysOfMonth.map((v) => v.value),
            days,
            time,
            timeZone,
        );

        let subscription;

        if (props.currentSubscription) {
            subscription = clone(props.currentSubscription);
            subscription.userReportSubscriptionSchedules = userReportSubscriptionSchedules;
            subscription.communities = subscriptionCommunities;
            delete subscription.user;
            delete subscription.reportMetadata;
        } else {
            subscription = {
                companyId: selectedCompanyId,
                userReportSubscriptionSchedules,
                communities: subscriptionCommunities,
            };
        }

        return subscription;
    }, [props.currentSubscription, daysOfMonth, days, time, timeZone, subscriptionCommunities, selectedCompanyId]);

    useEffect(() => {
        resetSubscription();
    }, [resetSubscription]);

    useEffect(() => {
        if (props.currentSubscription?.userReportSubscriptionSchedules?.length > 0) {
            let res = parseSubscriptionSchedules(props.currentSubscription.userReportSubscriptionSchedules);
            setParsedSubscription(res);
        } else {
            setParsedSubscription(null);
        }
    }, [props.currentSubscription]);

    return {
        ...props,
        setFrequencyFromEnum,
        closeDialog,
        toggleDeleteConfirmation,
        resetSubscription,
        createSubscription,
        showDeleteConfirmation,
        setShowDeleteConfirmation,
        frequency,
        daysOfMonth,
        setDaysOfMonth,
        days,
        setDays,
        time,
        setTime,
        timeZone,
        setTimeZone,
        userCommunities,
        setUserCommunities,
        subscriptionCommunities,
        setSubscriptionCommunities,
        parsedSubscription,
        setParsedSubscription,
    };
};

export default useSubscriptionEditor;
