import Grid from '@mui/material/Unstable_Grid2';
import { Checkbox, MenuItem, Select, TextField } from '@mui/material';
import React from 'react';
import { DAY_OF_MONTH_OPTIONS, DayOption, FrequencyOption } from '../../Subscriptions/subscriptionConstants';
import Autocomplete from '@mui/material/Autocomplete';

const SubscriptionDeliveryOptions = ({ isLoading, frequency, setFrequencyFromDropdown, daysOfMonth, setDaysOfMonth, days, setDays, errors }) => {
    return (
        <Grid>
            <h5 className={'modal__subheader--grey bottom-border'}>Delivery Options</h5>
            <h5 className={'modal__subheader'}>Frequency</h5>
            <Select
                disabled={isLoading}
                size={'small'}
                id={'subscription-frequency-dropdown'}
                className={'enum-option'}
                onChange={(e) => setFrequencyFromDropdown(e.target.value as FrequencyOption)}
                value={frequency}
            >
                {Object.keys(FrequencyOption)
                    .filter((o) => isNaN(Number(o)))
                    .map((option) => (
                        <MenuItem key={FrequencyOption[option]} value={FrequencyOption[option]}>
                            <div className={'enum-option'}>{option.toLowerCase()}</div>
                        </MenuItem>
                    ))}
            </Select>
            {frequency === FrequencyOption.MONTHLY && (
                <>
                    <h4 className={'modal__input-group__label'}>Days of the month</h4>
                    <div className="modal__input-group">
                        <Autocomplete
                            disabled={isLoading}
                            size={'small'}
                            multiple
                            autoComplete
                            fullWidth
                            disableCloseOnSelect
                            filterSelectedOptions
                            id={'subscription-day-of-month-dropdown'}
                            options={DAY_OF_MONTH_OPTIONS as any[]}
                            getOptionLabel={(option) => option.label}
                            getOptionKey={(option) => option.value}
                            onChange={(e, value) => setDaysOfMonth(value)}
                            value={daysOfMonth}
                            renderInput={(params) => <TextField {...params} />}
                        />
                    </div>
                    {errors?.daysOfMonth && <p className={'error-text'}>{errors.daysOfMonth}</p>}
                </>
            )}
            {frequency === FrequencyOption.WEEKLY && (
                <>
                    <h4 className={'modal__input-group__label'}>Days of the week</h4>
                    <div className="modal__input-group flex flex-wrap justify-content-center justify-content-between">
                        {Object.keys(DayOption)
                            .filter((o) => isNaN(Number(o)))
                            .map((day) => {
                                return (
                                    <div key={day} className="flex align-items-center">
                                        <Checkbox
                                            disabled={isLoading}
                                            id={day}
                                            name="day"
                                            value={day}
                                            onChange={(e) =>
                                                e.target.checked
                                                    ? setDays([...days, DayOption[day]])
                                                    : setDays(days.filter((d) => d !== DayOption[day]))
                                            }
                                            checked={days.includes(DayOption[day])}
                                        />
                                        <label htmlFor={day} className="enum-option ml-2">
                                            {day.slice(0, 3).toLowerCase()}
                                        </label>
                                    </div>
                                );
                            })}
                    </div>
                    {errors?.days && <p className={'error-text'}>{errors.days}</p>}
                </>
            )}
        </Grid>
    );
};

export default SubscriptionDeliveryOptions;
