import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../ApplicationContext';

type CategorizationNameProps = {
    categorizationId: string;
};

const CategorizationName = ({ categorizationId }: CategorizationNameProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId } = useApplicationContext();
    const [categorizationName, setCategorizationName] = useState('');

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            const url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Categorization/${categorizationId}/Name/`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setCategorizationName(response.data);
                });
        })();
    }, [getAccessTokenSilently, categorizationId, selectedCompanyId]);

    return <>{categorizationName}</>;
};

export default CategorizationName;
