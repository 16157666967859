import Grid from '@mui/material/Unstable_Grid2';
import { TextField } from '@mui/material';
import React from 'react';
import Autocomplete from '@mui/material/Autocomplete';

const SubscriptionReportFilters = ({ disabled, options, value, onChange, error }) => {
    return (
        <Grid>
            <h5 className={'modal__subheader--grey bottom-border'}>Report Filters</h5>
            <h5 className={'modal__subheader'}>Communities</h5>
            <Autocomplete
                disabled={disabled}
                size={'small'}
                multiple
                limitTags={2}
                autoComplete
                fullWidth
                disableCloseOnSelect
                filterSelectedOptions
                id={'subscription-communities-dropdown'}
                options={options}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                getOptionLabel={(option) => option.name}
                getOptionKey={(option) => option.id}
                onChange={(e, value) => onChange(value)}
                value={value}
                renderInput={(params) => <TextField {...params} />}
            />
            {error != null && <p className={'error-text'}>{error}</p>}
        </Grid>
    );
};

export default SubscriptionReportFilters;
