import logo from '../../seniorlytics-logo.png';
import ToggleSideMenuButton from '../ToggleButton';
import Grid from '@mui/material/Unstable_Grid2';
import CompanyDropdown from '../CompanyDropdown/CompanyDropdown';
import UserMenu from '../UserMenu/UserMenu';
import './Topbar.css';

export default function SideMenu() {
    return (
        <>
            <Grid container className={'top-bar__wrapper'} component={'header'}>
                <Grid>
                    <ToggleSideMenuButton />
                </Grid>
                <Grid className={'top-bar__logo'}>
                    <img src={logo} alt="logo" />
                </Grid>
                <Grid className={'top-bar__company-selector-wrapper'}>
                    <CompanyDropdown />
                </Grid>
                <Grid>
                    <UserMenu />
                </Grid>
            </Grid>
        </>
    );
}
