import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import React, { useCallback, useRef, useState } from 'react';
import { useApplicationContext } from '../ApplicationContext';
import { Outlet } from 'react-router-dom';
import { FileFormats } from '../lib/constants';
import { enqueueSnackbar } from 'notistack';
import useReportService from '../hooks/useReportService';

const ReportContext = React.createContext(null);

const useReportContext = () => {
    const context = React.useContext(ReportContext);

    if (context === undefined) {
        throw new Error('useReportContext must be used within an ReportContext.Provider');
    }
    return context;
};

export interface ReportComment {
    comment: string;
    year: number;
    month: number;
    communityId: string;
    reportId: string;
}

const Provider = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId, isOwner } = useApplicationContext();
    const { getReportComment, addReportComment, deleteReportComment } = useReportService();

    const [exportProgress, setExportProgress] = useState(null);
    const reportRef = useRef<any>();

    const onFail = (message: string, error: string) => {
        enqueueSnackbar(`${message}: ${error}`, { variant: 'error' });
        setExportProgress(null);
    };

    const getComment = useCallback(
        async (reportId: string, year: number, month: number, communityId: string) => {
            const date = new Date(year, month, 1);
            return getReportComment(reportId, date.toISOString(), communityId);
        },
        [getReportComment],
    );

    const addComment = useCallback(
        async ({ comment = '', communityId, reportId, year, month }: ReportComment) => {
            addReportComment({ comment, communityId, reportId, year, month }).catch((e) => onFail('Failed to add comment', e));
        },
        [addReportComment],
    );

    const deleteComment = useCallback(
        async (commentId: any) => {
            deleteReportComment(commentId).catch((e) => onFail('Failed to remove comment', e));
        },
        [deleteReportComment],
    );

    const downloadReportPDF = useCallback(
        async (exportId: string, reportId: string, fileName: string) => {
            const token = await getAccessTokenSilently();
            const downloadQueryParams = new URLSearchParams();
            const downloadUrl = `${process.env.REACT_APP_SENIORLYTICS_API}/api/EmbedInfo/GetExportedFile`;

            downloadQueryParams.append('reportId', reportId);
            downloadQueryParams.append('exportId', exportId);
            await axios
                .get(downloadUrl, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: downloadQueryParams,
                    responseType: 'blob',
                })
                .then(async (response) => {
                    if (response.status === 200) {
                        const fileStream = response.data;

                        const link = document.createElement('a');
                        const objectURL = URL.createObjectURL(fileStream);
                        link.href = objectURL;
                        link.download = fileName;
                        link.click();
                        link.remove();
                        URL.revokeObjectURL(objectURL);
                        setExportProgress(101);
                        setTimeout(() => {
                            setExportProgress(null);
                        }, 2000);
                    } else {
                        console.error(`Failed to get exported file: ${response.status}`);
                    }
                })
                .catch((e) => onFail('Failed to export', e));
        },
        [getAccessTokenSilently],
    );

    const pollExportRequest = useCallback(
        async (exportId: string, reportId: string) => {
            const token = await getAccessTokenSilently();
            const pollQueryParams = new URLSearchParams();
            const pollUrl = `${process.env.REACT_APP_SENIORLYTICS_API}/api/EmbedInfo/PollExportRequest`;

            pollQueryParams.append('reportId', reportId);
            pollQueryParams.append('exportId', exportId);
            const poll = async () => {
                await axios
                    .post(pollUrl, null, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: pollQueryParams,
                    })
                    .then((response) => {
                        if (response.data.status !== 'Succeeded') {
                            setExportProgress(response.data.percentComplete);
                            setTimeout(poll, 250);
                        } else {
                            const reportName = response.data.reportName;
                            const yyyyMMdd = new Date().toISOString().split('T')[0];
                            downloadReportPDF(exportId, reportId, `${reportName}_${yyyyMMdd}.pdf`);
                        }
                    })
                    .catch((e) => onFail('Failed to export', e));
            };
            await poll();
        },
        [downloadReportPDF, getAccessTokenSilently],
    );

    const exportReport = useCallback(
        async (reportId) => {
            if (reportRef.current) {
                setExportProgress(0);
                const token = await getAccessTokenSilently();
                const postQueryParams = new URLSearchParams();
                postQueryParams.append('reportId', reportId);
                postQueryParams.append('companyId', selectedCompanyId);
                postQueryParams.append('isOwner', isOwner);
                postQueryParams.append('fileFormat', FileFormats.PDF);
                const bookmark = await reportRef.current?.embed.bookmarksManager.capture();
                postQueryParams.append('bookmarkState', bookmark.state);
                const postUrl = `${process.env.REACT_APP_SENIORLYTICS_API}/api/EmbedInfo/PostExportRequest`;

                return await axios
                    .post(postUrl, null, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: postQueryParams,
                    })
                    .then((response) => {
                        pollExportRequest(response.data, reportId);
                    })
                    .catch((e) => onFail('Failed to export', e));
            }
        },
        [selectedCompanyId, getAccessTokenSilently, pollExportRequest, isOwner],
    );

    return (
        <ReportContext.Provider
            value={{
                exportReport,
                exportProgress,
                reportRef,
                getComment,
                addComment,
                deleteComment,
            }}
        >
            <Outlet />
        </ReportContext.Provider>
    );
};

const ReportProvider = Provider;

export { ReportContext, ReportProvider, useReportContext };
