import { useAuth0 } from '@auth0/auth0-react';
import { useApplicationContext } from '../../ApplicationContext';
import { useEffect, useState } from 'react';
import axios from 'axios';

type GLCodeSetNameProps = {
    glCodeSetId: string;
};
const GLCodeSetName = ({ glCodeSetId }: GLCodeSetNameProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId } = useApplicationContext();
    const [codeSetName, setCodeSetName] = useState('');

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            const url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/GLCodeSet/${glCodeSetId}/Name/`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setCodeSetName(response.data);
                });
        })();
    }, [getAccessTokenSilently, glCodeSetId, selectedCompanyId]);

    return <>{codeSetName}</>;
};

export default GLCodeSetName;
