import { useEffect, useState } from 'react';
import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useApplicationContext } from '../ApplicationContext';

const useCommunities = (requireUserAccess = false) => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId, isOwner } = useApplicationContext();
    const [communities, setCommunities] = useState([]);

    useEffect(() => {
        const getCommunities = async () => {
            const token = await getAccessTokenSilently();
            const url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Company/Communities`;
            await axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: { selectedCompanyId, isOwner, requireUserAccess },
                })
                .then((response) => {
                    setCommunities(response.data);
                });
        };
        getCommunities();
    }, [getAccessTokenSilently, isOwner, selectedCompanyId, setCommunities, requireUserAccess]);

    return { communities };
};

export default useCommunities;
