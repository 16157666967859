import Grid from '@mui/material/Unstable_Grid2';
import { Button, Stack } from '@mui/material';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';
import { LoadingButton } from '@mui/lab';
import React from 'react';

const SubscriptionFooter = ({
    showDeleteConfirmation,
    isDisabled,
    isSaving,
    toggleDeleteConfirmation,
    unSubscribe,
    closeDialog,
    upsertSubscription,
}) => {
    return (
        <Grid container direction={'column'} spacing={1}>
            {showDeleteConfirmation ? (
                <>
                    <Grid>
                        <Stack direction={'row'} alignItems={'center'}>
                            <WarningAmberRoundedIcon fontSize={'small'} sx={{ marginRight: 1 }} />
                            Are you sure that you want to delete this subscription
                        </Stack>
                    </Grid>
                    <Grid container alignSelf={'end'} spacing={2} sx={{ marginTop: 1 }}>
                        <Grid>
                            <Button disabled={isDisabled} onClick={toggleDeleteConfirmation}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid>
                            <LoadingButton variant={'contained'} color={'error'} loading={isSaving} onClick={unSubscribe}>
                                <span>Delete</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </>
            ) : (
                <Grid container justifyContent={'space-between'}>
                    <Grid>
                        <Button color={'error'} disabled={isDisabled} onClick={toggleDeleteConfirmation}>
                            Delete
                        </Button>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid>
                            <Button disabled={isDisabled} onClick={closeDialog}>
                                Cancel
                            </Button>
                        </Grid>
                        <Grid>
                            <LoadingButton disabled={isDisabled} variant={'contained'} loading={isSaving} onClick={upsertSubscription}>
                                <span>Save</span>
                            </LoadingButton>
                        </Grid>
                    </Grid>
                </Grid>
            )}
        </Grid>
    );
};

export default SubscriptionFooter;
