import { DayOption, FrequencyOption, TimeZoneOption } from './subscriptionConstants';
import { UserReportSubscriptionSchedule } from '../../lib/types';
import { ParseSubscriptionScheduleResult } from './subscriptionTypes';

export const parseSubscriptionSchedules: (subscriptionSchedules: UserReportSubscriptionSchedule[]) => ParseSubscriptionScheduleResult = (
    subscriptionSchedules: UserReportSubscriptionSchedule[],
) => {
    let daysOfMonth: Set<string> = new Set<string>();
    let days: Set<DayOption> = new Set<DayOption>();

    let time: number = parseInt(subscriptionSchedules[0].timeOfDay.split(':')[0]);
    let timeZone: TimeZoneOption = TimeZoneOption[subscriptionSchedules[0].timeZone];

    subscriptionSchedules.forEach((schedule) => {
        if (schedule.dayOfMonth) {
            daysOfMonth.add(schedule.dayOfMonth);
        } else if (schedule.dayOfWeek) {
            days.add(DayOption[schedule.dayOfWeek]);
        }
    });

    const frequency = (daysOfMonth.size > 0 && FrequencyOption.MONTHLY) || (days.size > 0 && FrequencyOption.WEEKLY) || FrequencyOption.DAILY;

    return {
        daysOfMonth: Array.from(daysOfMonth),
        days: Array.from(days),
        time,
        timeZone,
        frequency,
    };
};

export const createSubscriptionSchedules = (daysOfMonth: string[], daysOfWeek: DayOption[], timeOfDay: number, timeZone: TimeZoneOption) => {
    let subscriptionSchedules: UserReportSubscriptionSchedule[] = [];

    const createScheduleItem = (dom, d, t, tz) => {
        return {
            dayOfMonth: dom,
            dayOfWeek: d,
            timeOfDay: `${t}:00:00.0000000`,
            timeZone: tz,
        };
    };

    if (daysOfMonth.length > 0) {
        daysOfMonth.forEach((dom) => {
            subscriptionSchedules.push(createScheduleItem(dom, null, timeOfDay, TimeZoneOption[timeZone]));
        });
    } else if (daysOfWeek.length > 0) {
        daysOfWeek.forEach((dayOfWeek) => {
            subscriptionSchedules.push(createScheduleItem(null, DayOption[dayOfWeek], timeOfDay, TimeZoneOption[timeZone]));
        });
    } else {
        subscriptionSchedules.push(createScheduleItem(null, null, timeOfDay, TimeZoneOption[timeZone]));
    }

    return subscriptionSchedules;
};

const removeLeadingZero = (str: string) => {
    return str.replace(/^0+/, '');
};

const timeStringFromHHMMSS = (time: string) => {
    const [hour, minute] = time.split(':').map((t) => parseInt(t));
    const suffix = hour >= 12 ? 'PM' : 'AM';
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minute.toString().padStart(2, '0')} ${suffix}`;
};

const numberWithSuffix = (n: string) => {
    const isNumber = /^\d+$/.test(n);
    if (!isNumber) {
        return n;
    }
    const day = parseInt(n);

    if (day > 3 && day < 21) return `${day}th`;
    switch (day % 10) {
        case 1:
            return `${day}st`;
        case 2:
            return `${day}nd`;
        case 3:
            return `${day}rd`;
        default:
            return `${day}th`;
    }
};

const joinAllCommaButLastAnd = (arr: string[]) => {
    return arr.slice(0, arr.length - 1).join(', ') + (arr.length > 1 ? ', and ' : '') + arr[arr.length - 1];
};

export const subscriptionScheduleToString = (schedules: UserReportSubscriptionSchedule[]) => {
    const data = parseSubscriptionSchedules(schedules);
    let result = '';

    if (schedules.length === 0) {
        return 'N/A';
    }

    const time = timeStringFromHHMMSS(removeLeadingZero(schedules[0].timeOfDay));
    const timeZone = schedules[0].timeZone;

    if (data.frequency === FrequencyOption.DAILY) {
        result = `Every day @ ${time} ${timeZone}`;
    } else if (data.frequency === FrequencyOption.WEEKLY) {
        result = `Every ${data.days.map((d) => DayOption[d].toLowerCase()).join(', ')} @ ${time} ${timeZone}`;
    } else if (data.frequency === FrequencyOption.MONTHLY) {
        result = `Every ${joinAllCommaButLastAnd(
            data.daysOfMonth.sort((a, b) => parseInt(a) - parseInt(b)).map(numberWithSuffix),
        )} @ ${time} ${timeZone}`;
    }

    return result;
};
