import { withAuthenticationRequired } from '@auth0/auth0-react';
import { Outlet, useMatches, useNavigate } from 'react-router-dom';
import { useApplicationContext } from './ApplicationContext';
import { Layout } from './Layout';
import { useEffect, useState } from 'react';

const ProtectedRoute = () => {
    const { currentUser } = useApplicationContext();
    const matches = useMatches();
    const navigate = useNavigate();
    const [allowed, setAllowed] = useState(true);
    const [loading, setLoading] = useState(true);
    const [requiredPermissions, setRequiredPermissions] = useState(new Set());

    useEffect(() => {
        setLoading(true);
        setAllowed(true);
        matches
            .filter((match) => Boolean((match.handle as any)?.permission))
            .forEach((e) => {
                if (currentUser && !currentUser.permissions?.some((permission) => permission.permissionName === (e.handle as any).permission)) {
                    setAllowed(false);
                    setRequiredPermissions((prev) => prev.add((e.handle as any).permission));
                }
            });
        setLoading(false);
    }, [currentUser, matches]);

    useEffect(() => {
        if (!allowed) {
            alert('The following permissions are required to access this page: ' + [...requiredPermissions].join(', '));
            navigate(-1);
        }
    }, [allowed, navigate, requiredPermissions]);

    return <Layout>{loading || !allowed ? null : <Outlet />}</Layout>;
};

export default withAuthenticationRequired(ProtectedRoute);
