import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../ApplicationContext';

type ReportNameProps = {
    reportId: string;
};

const ReportName = ({ reportId }: ReportNameProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const [reportName, setReportName] = useState('');
    const { selectedCompanyId } = useApplicationContext();

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            const url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Reports/${reportId}/GetName`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: { companyId: selectedCompanyId },
                })
                .then((response) => {
                    setReportName(response.data);
                });
        })();
    }, [getAccessTokenSilently, reportId, selectedCompanyId]);

    return <>{reportName}</>;
};

export default ReportName;
