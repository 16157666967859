import { useApplicationContext } from './ApplicationContext';
import SideMenu from './Components/SideMenu';
import ToggleSideMenuButton from './Components/ToggleButton';
import Topbar from './Components/Topbar/Topbar';
import BreadCrumbs from './Components/BreadCrumbs';
import { Drawer } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';

export function Layout({ children }) {
    const { navIsVisible, toggleNav } = useApplicationContext();

    return (
        <>
            <Topbar />
            <div className="App">
                <Drawer open={navIsVisible} onClose={toggleNav}>
                    <Grid container direction={'column'}>
                        <Grid className={'drawer-menu__button'}>
                            <ToggleSideMenuButton />
                        </Grid>
                        <Grid container>
                            <SideMenu />
                        </Grid>
                    </Grid>
                </Drawer>
                <BreadCrumbs />
                <main className="main-wrapper">{children}</main>
            </div>
        </>
    );
}
