import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import { useCallback } from 'react';
import { ReportComment } from '../contexts/ReportContext';
import { useApplicationContext } from '../ApplicationContext';

const REPORT_API_URL = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Reports`;

const useReportService = () => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId } = useApplicationContext();

    const getReportById = useCallback(
        async (reportId: string) => {
            const params = new URLSearchParams();
            params.append('companyId', selectedCompanyId);

            return getAccessTokenSilently().then((token) => {
                return axios.get(`${REPORT_API_URL}/${reportId}/ReportMetadata`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'text/json',
                    },
                    params,
                });
            });
        },
        [getAccessTokenSilently, selectedCompanyId],
    );

    const getReportComment = useCallback(
        async (reportId: string, date: string, communityId: string) => {
            const params = new URLSearchParams();
            params.append('reportId', reportId);
            params.append('date', date);
            params.append('communityId', communityId);

            return getAccessTokenSilently().then((token) => {
                return axios.get(`${REPORT_API_URL}/Comments`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'text/json',
                    },
                    params,
                });
            });
        },
        [getAccessTokenSilently],
    );

    const addReportComment = useCallback(
        async ({ comment = '', communityId, reportId, year, month }: ReportComment) => {
            const token = await getAccessTokenSilently();
            const commentUrl = `${REPORT_API_URL}/Comments/AddComment`;
            const date = new Date(year, month, 1);

            return await axios.post(
                commentUrl,
                {
                    comment,
                    communityId,
                    reportId,
                    date,
                },
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                },
            );
        },
        [getAccessTokenSilently],
    );

    const deleteReportComment = useCallback(
        async (commentId: any) => {
            const token = await getAccessTokenSilently();
            const deleteUrl = `${REPORT_API_URL}/Comments/${commentId}`;

            return await axios.delete(deleteUrl, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
        },
        [getAccessTokenSilently],
    );

    return { getReportById, getReportComment, addReportComment, deleteReportComment };
};

export default useReportService;
