import { FrequencyOption } from './subscriptionConstants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import Modal from '../Modal/Modal';
import { useApplicationContext } from '../../ApplicationContext';
import { EditSubscriptionModalProps, SubscriptionError } from './subscriptionTypes';
import { Stack, Tooltip, useTheme } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import useMediaQuery from '@mui/material/useMediaQuery';
import SubscriptionFooter from '../Common/Subscription/SubscriptionFooter';
import SubscriptionDeliveryTime from '../Common/Subscription/SubscriptionDeliveryTime';
import SubscriptionDeliveryOptions from '../Common/Subscription/SubscriptionDeliveryOptions';
import SubscriptionReportFilters from '../Common/Subscription/SubscriptionReportFilters';
import useSubscriptionEditor from '../../hooks/useSubscriptionEditor';

const UserEditSubscriptionModal = (props: EditSubscriptionModalProps) => {
    const {
        reportId,
        report,
        isDashboard,
        currentSubscription,
        getCurrentSubscription,
        setIsSubscribed,
        showDialog,
        setShowDialog,
        refreshCallback,
        setFrequencyFromEnum,
        closeDialog,
        toggleDeleteConfirmation,
        resetSubscription,
        createSubscription,
        showDeleteConfirmation,
        setShowDeleteConfirmation,
        frequency,
        daysOfMonth,
        setDaysOfMonth,
        days,
        setDays,
        time,
        setTime,
        timeZone,
        setTimeZone,
        userCommunities,
        setUserCommunities,
        subscriptionCommunities,
        setSubscriptionCommunities,
    } = useSubscriptionEditor(props);

    const { selectedCompanyId, isOwner } = useApplicationContext();
    const { getAccessTokenSilently, user } = useAuth0();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [isLoading, setIsLoading] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (showDialog && userCommunities.length === 0) {
            setIsLoading(true);
            getAccessTokenSilently().then((token) => {
                axios
                    .get(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Users/GetCommunities`, {
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                        params: { companyId: selectedCompanyId, isOwner },
                    })
                    .then((response) => {
                        let newCommunities = response.data.map((c) => ({ name: c.name, id: c.id }));
                        setUserCommunities(newCommunities);

                        if (currentSubscription?.communities?.length > 0) {
                            setSubscriptionCommunities(currentSubscription?.communities.map((c) => ({ name: c.name, id: c.id })));
                        } else {
                            setSubscriptionCommunities(newCommunities);
                        }
                    })
                    .finally(() => setIsLoading(false));
            });
        }
    }, [
        getAccessTokenSilently,
        selectedCompanyId,
        showDialog,
        currentSubscription,
        isOwner,
        userCommunities,
        setUserCommunities,
        setSubscriptionCommunities,
    ]);

    const errors = useMemo<SubscriptionError>(() => {
        let errors: SubscriptionError = {};
        if (frequency === FrequencyOption.WEEKLY && days?.length === 0) {
            errors.days = 'Please select at least one day';
        }
        if (frequency === FrequencyOption.MONTHLY && daysOfMonth?.length === 0) {
            errors.daysOfMonth = 'Please select at least one day of the month';
        }
        if (subscriptionCommunities?.length === 0) {
            errors.communities = 'Please select at least one community';
        }
        return errors;
    }, [days, daysOfMonth, subscriptionCommunities, frequency]);

    const upsertSubscription = useCallback(() => {
        if (!Object.keys(errors).length) {
            setIsSaving(true);
            setIsLoading(true);
            let subscription = createSubscription();

            let data = JSON.stringify(subscription);

            return getAccessTokenSilently()
                .then((token) => {
                    return axios
                        .post(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Subscriptions/${reportId}/SetSubscription`, data, {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                        })
                        .then(() => {
                            refreshCallback();
                            setShowDialog(false);
                            getCurrentSubscription(token);
                            setIsSubscribed(true);
                        });
                })
                .catch(console.log)
                .finally(() => {
                    setIsLoading(false);
                    setIsSaving(false);
                });
        }
    }, [getAccessTokenSilently, reportId, refreshCallback, getCurrentSubscription, setIsSubscribed, setShowDialog, errors, createSubscription]);

    const unSubscribe = useCallback(() => {
        setIsSaving(true);
        return getAccessTokenSilently()
            .then((token) => {
                return axios
                    .post(
                        `${process.env.REACT_APP_SENIORLYTICS_API}/api/Subscriptions/${reportId}/UnSubscribe`,
                        {},
                        {
                            headers: {
                                Authorization: `Bearer ${token}`,
                                'Content-Type': 'application/json',
                            },
                            params: { companyId: selectedCompanyId },
                        },
                    )
                    .then(() => {
                        setIsSubscribed(false);
                        resetSubscription();
                        getCurrentSubscription(token);
                        refreshCallback();
                        setShowDeleteConfirmation(false);
                    });
            })
            .then(() => {
                setShowDialog(false);
            })
            .catch(console.log)
            .finally(() => setIsSaving(false));
    }, [
        getAccessTokenSilently,
        reportId,
        selectedCompanyId,
        setIsSubscribed,
        resetSubscription,
        getCurrentSubscription,
        refreshCallback,
        setShowDialog,
        setShowDeleteConfirmation,
    ]);

    return (
        <>
            <Modal isLoading={isLoading} open={showDialog} title={'Edit Subscription'} onClose={closeDialog}>
                <Grid container direction={'column'} spacing={3}>
                    <Grid container direction={'column'} spacing={2}>
                        <Grid>
                            <h5 className={'modal__subheader--grey bottom-border'}>
                                {isDashboard ? 'Dashboard' : 'Report'} | {report.displayName}
                            </h5>
                            <Stack direction={isMobile ? 'column' : 'row'} spacing={isMobile ? 0 : 3}>
                                <Grid>
                                    <h5 className={'modal__subheader'}>Subscription will be delivered to</h5>
                                    <p className={'modal__body'}>{user.email}</p>
                                </Grid>
                                <Grid sx={{ position: 'relative', pr: 3 }}>
                                    <h5 className={'modal__subheader'}>
                                        Delivery mode
                                        <Tooltip
                                            title={
                                                'When set to Portfolio, the subscriber will receive a single email with the report containing all communities. When set to Single Community the subscriber will receive one email per community.'
                                            }
                                            placement="top"
                                        >
                                            <InfoRoundedIcon
                                                color={'action'}
                                                sx={{
                                                    position: 'absolute',
                                                    top: '.3rem',
                                                    marginLeft: '.5rem',
                                                    fontSize: '1.2rem',
                                                }}
                                            />
                                        </Tooltip>
                                    </h5>
                                    <p className={'modal__body'}>{report.useSingleCommunitySubscription ? 'Single Community' : 'Portfolio'}</p>
                                </Grid>
                            </Stack>
                        </Grid>
                        <Grid>
                            <SubscriptionReportFilters
                                disabled={userCommunities.length === 0 || isLoading}
                                options={userCommunities}
                                value={subscriptionCommunities}
                                onChange={setSubscriptionCommunities}
                                error={userCommunities.length > 0 ? errors?.communities : null}
                            />
                        </Grid>
                        <Grid>
                            <SubscriptionDeliveryOptions
                                isLoading={isLoading}
                                frequency={frequency}
                                setFrequencyFromDropdown={setFrequencyFromEnum}
                                daysOfMonth={daysOfMonth}
                                setDaysOfMonth={setDaysOfMonth}
                                days={days}
                                setDays={setDays}
                                errors={errors}
                            />
                        </Grid>
                        <Grid>
                            <SubscriptionDeliveryTime
                                isLoading={isLoading}
                                time={time}
                                setTime={setTime}
                                timeZone={timeZone}
                                setTimeZone={setTimeZone}
                            />
                        </Grid>
                    </Grid>
                    <Grid>
                        <SubscriptionFooter
                            showDeleteConfirmation={showDeleteConfirmation}
                            isDisabled={Object.keys(errors).length > 0 || subscriptionCommunities.length === 0 || isLoading}
                            isSaving={isSaving}
                            toggleDeleteConfirmation={toggleDeleteConfirmation}
                            unSubscribe={unSubscribe}
                            closeDialog={closeDialog}
                            upsertSubscription={upsertSubscription}
                        />
                    </Grid>
                </Grid>
            </Modal>
        </>
    );
};

export default UserEditSubscriptionModal;
