import { GridGroupingColDefOverride } from '@mui/x-data-grid-pro';
import { Typography } from '@mui/material';
import React from 'react';

const getNumberOption = (i) => ({
    value: i + 1,
    label: (i + 1).toString(),
});

/* Budget Version Month Options */
export const MONTH_OPTIONS = [
    { value: 0, label: 'Jan', fullName: 'January' },
    { value: 1, label: 'Feb', fullName: 'February' },
    { value: 2, label: 'Mar', fullName: 'March' },
    { value: 3, label: 'Apr', fullName: 'April' },
    { value: 4, label: 'May', fullName: 'May' },
    { value: 5, label: 'Jun', fullName: 'June' },
    { value: 6, label: 'Jul', fullName: 'July' },
    { value: 7, label: 'Aug', fullName: 'August' },
    { value: 8, label: 'Sep', fullName: 'September' },
    { value: 9, label: 'Oct', fullName: 'October' },
    { value: 10, label: 'Nov', fullName: 'November' },
    { value: 11, label: 'Dec', fullName: 'December' },
];

export const MONTH_OPTION_HEADER = (month, date) => (
    <Typography>
        <Typography>{month.label}</Typography>
        <Typography variant={'body2'}>
            {date.getMonth() + 1}/{date.getDate()}
        </Typography>
    </Typography>
);

export const getMonthOptions = (budgetVersion, entryRowUpdates) => {
    return MONTH_OPTIONS.map((m) => {
        let date = new Date(budgetVersion.budget?.year, 0);
        date = new Date(date.setMonth(m.value));

        const findEntry = (e) => {
            return new Date(e.date).getMonth() === m.value;
        };

        return {
            field: m.label,
            sortable: false,
            type: 'number',
            editable: true,
            renderHeader: () => MONTH_OPTION_HEADER(m, date),
            disableColumnMenu: true,
            getDate: () => date,
            findEntry,
            valueGetter: (params) => {
                let updateAmount = entryRowUpdates[params.row.budgetCategoryOptionId]?.entries?.find(findEntry)?.amount;
                let entry = (params.row.budgetVersionEntries || params.row.dateTotals).find(findEntry);

                return updateAmount ?? entry?.amount ?? 0;
            },
        };
    });
};

/* Budget Version Week Options */
export const WEEK_OPTIONS = Array.from({ length: 53 }, (_, i) => getNumberOption(i));
export const WEEK_OPTION_HEADER = (week, date) => {
    const endOfWeek = new Date(date);
    endOfWeek.setDate(endOfWeek.getDate() + 6);

    return (
        <Typography>
            <Typography>Week {week.label}</Typography>
            <Typography variant={'body2'}>
                {date.getMonth() + 1}/{date.getDate()} - {endOfWeek.getMonth() + 1}/{endOfWeek.getDate()}
            </Typography>
        </Typography>
    );
};
export const getWeekOptions = (budgetVersion, entryRowUpdates) => {
    return WEEK_OPTIONS.map((w) => {
        let date = new Date(budgetVersion.budget?.year, 0);
        date = new Date(date.setDate(w.value * 7 - 1));
        const findEntry = (e) => {
            const getWeekOfYear = (date) => {
                const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
                const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
                return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
            };

            return getWeekOfYear(new Date(e.date)) === w.value;
        };

        return {
            field: w.label,
            sortable: false,
            width: 108,
            type: 'number',
            editable: true,
            renderHeader: () => WEEK_OPTION_HEADER(w, date),
            disableColumnMenu: true,
            getDate: () => date,
            findEntry,
            valueGetter: (params) => {
                let updateAmount = entryRowUpdates[params.row.budgetCategoryOptionId]?.entries?.find(findEntry)?.amount;
                let entry = (params.row.budgetVersionEntries || params.row.dateTotals).find(findEntry);

                return updateAmount ?? entry?.amount ?? 0;
            },
        };
    });
};

export const DAY_OPTIONS = (isLeapYear) => Array.from({ length: isLeapYear ? 366 : 365 }, (_, i) => getNumberOption(i));
export const DAYS_OF_WEEK = {
    0: 'Su',
    1: 'M',
    2: 'Tu',
    3: 'W',
    4: 'Th',
    5: 'F',
    6: 'Sa',
};
export const DAY_OPTION_HEADER = (date: Date) => (
    <Typography>
        <Typography>{DAYS_OF_WEEK[date.getDay()]}</Typography>
        <Typography variant={'body2'}>
            {date.getMonth() + 1}/{date.getDate()}
        </Typography>
    </Typography>
);
export const getDayOptions = (budgetVersion, entryRowUpdates) => {
    const isLeapYear = (budgetVersion?.budget.year % 4 === 0 && budgetVersion?.budget.year % 100 !== 0) || budgetVersion?.budget.year % 400 === 0;
    const dayOptions = DAY_OPTIONS(isLeapYear);

    return dayOptions.map((d) => {
        const date = new Date(budgetVersion.budget?.year, 0, d.value);

        return {
            field: d.label,
            sortable: false,
            width: 72,
            type: 'number',
            editable: true,
            renderHeader: () => DAY_OPTION_HEADER(date),
            disableColumnMenu: true,
            defaultValue: 0,
            test: 'test',
            getDate: () => date,
            findEntry: (e) => {
                const dayOfYear = (date) => {
                    return Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24);
                };
                return dayOfYear(new Date(e.date)) === d.value;
            },
            valueGetter: (params) => {
                const findEntry = (e) => {
                    const dayOfYear = (date) => {
                        return Math.floor((date.getTime() - new Date(date.getFullYear(), 0, 0).getTime()) / 1000 / 60 / 60 / 24);
                    };
                    return dayOfYear(new Date(e.date)) === d.value;
                };

                let updateAmount = entryRowUpdates[params.row.budgetCategoryOptionId]?.entries?.find(findEntry)?.amount;
                let entry = (params.row.budgetVersionEntries || params.row.dateTotals).find(findEntry);

                return updateAmount ?? entry?.amount ?? 0;
            },
        };
    });
};

export interface OptionType {
    id?: number | string;
    occupancyType?: { name: string };
    censusType?: { name: string };
    laborType?: { jobTitle: string };
    inputValue?: string;
}

export const GROUPING_COL_DEF: GridGroupingColDefOverride = {
    headerName: 'Category',
    valueGetter: (params) => {
        return params.row.categoryName || '--';
    },
};
