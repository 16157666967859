import React, { useCallback } from 'react';
import { Box, CircularProgress, IconButton, Tooltip } from '@mui/material';
import { useReportContext } from '../../contexts/ReportContext';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded';

const ReportExportButton = ({ reportId }) => {
    const { exportReport, exportProgress } = useReportContext();
    const _exportReport = useCallback(async () => {
        await exportReport(reportId);
    }, [reportId, exportReport]);
    let done = exportProgress >= 100;
    let isExporting = exportProgress != null;
    let isSmall = isExporting && !done;
    const dimensions = !isSmall ? 40 : 36;
    const color = done ? 'success' : 'primary';

    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', alignItems: 'center', top: -1 }}>
            {done ? (
                <CheckCircleOutlineRoundedIcon color={'success'} fontSize={'large'} />
            ) : (
                <Tooltip title={'Export PDF'}>
                    <IconButton
                        size={isSmall ? 'small' : 'medium'}
                        sx={{ height: dimensions, width: dimensions }}
                        aria-label="save"
                        color={color}
                        onClick={!isExporting ? _exportReport : null}
                    >
                        {done ? (
                            <CheckRoundedIcon fontSize={isSmall ? 'small' : 'medium'} />
                        ) : (
                            <FileDownloadOutlinedIcon fontSize={isSmall ? 'small' : 'medium'} />
                        )}
                    </IconButton>
                </Tooltip>
            )}

            {isSmall && (
                <CircularProgress
                    variant={exportProgress === 0 ? 'indeterminate' : 'determinate'}
                    value={exportProgress}
                    thickness={5}
                    size={36}
                    color={color}
                    sx={{
                        position: 'absolute',
                        top: -0,
                        left: -0,
                        zIndex: 1,
                        opacity: 0.6,
                    }}
                />
            )}
        </Box>
    );
};

export default ReportExportButton;
