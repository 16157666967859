import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { useApplicationContext } from '../../../ApplicationContext';

type CommunityNameProps = {
    communityId: string;
};

const CommunityName = ({ communityId }: CommunityNameProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId } = useApplicationContext();
    const [communityName, setCommunityName] = useState('');

    useEffect(() => {
        (async () => {
            const token = await getAccessTokenSilently();
            const url = `${process.env.REACT_APP_SENIORLYTICS_API}/api/Company/${selectedCompanyId}/Communities/${communityId}/Name/`;
            axios
                .get(url, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    setCommunityName(response.data);
                });
        })();
    }, [getAccessTokenSilently, communityId, selectedCompanyId]);

    return <>{communityName}</>;
};

export default CommunityName;
