import React, { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';

const ApplicationContext = React.createContext(null);

const useApplicationContext = () => {
    const context = React.useContext(ApplicationContext);

    if (context === undefined) {
        throw new Error('useApplicationContext must be used within an ApplicationContext.Provider');
    }
    return context;
};

const Provider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();
    const [navIsVisible, setNavIsVisible] = useState(false);
    const [companies, setCompanies] = useState([]);
    const [selectedCompanyId, setSelectedCompanyId] = useState(Number(sessionStorage.getItem('selectedCompanyId')) || undefined);
    const [isOwner, setIsOwner] = useState(sessionStorage.getItem('isOwner') ? sessionStorage.getItem('isOwner') === 'true' : undefined);
    const [currentUser, setCurrentUser] = useState();
    const [location, setLocation] = useState<any>({});

    const toggleNav = () => {
        setNavIsVisible(!navIsVisible);
    };

    const setTrackingDimension = (dimension: string, value: Number) => {
        (window as any).clarity && (window as any).clarity('set', dimension, value);
    };

    const selectCompany = useCallback((value, owns) => {
        sessionStorage.setItem('selectedCompanyId', value);
        sessionStorage.setItem('isOwner', owns);
        setSelectedCompanyId(value);
        setIsOwner(owns);
        setTrackingDimension('selectedCompanyId', value);
    }, []);

    useEffect(() => {
        const _setLocation = (position: any) => {
            if (!position.coords) return;
            setLocation({
                latitude: position.coords.latitude,
                longitude: position.coords.longitude,
            });
        };
        navigator.geolocation.getCurrentPosition(_setLocation, _setLocation);
    }, []);

    useEffect(() => {
        getAccessTokenSilently().then(async (token) => {
            await axios
                .post(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Users/RefreshCurrentUserLastActiveAt`, null, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .catch(console.error);

            return await axios
                .get(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Company/PostLogin`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                })
                .then((response) => {
                    let user = response.data.user;
                    let companyList = response.data.companies.map((c) => {
                        return { id: c.id, name: c.name, isOwner: c.isOwner };
                    });
                    setCompanies(companyList);
                    setCurrentUser(user);
                    setTrackingDimension('userId', user.id);
                    if (!selectedCompanyId && isOwner === undefined) {
                        let company;
                        if (user.defaultCompanyId) {
                            company = companyList.find((c) => c.id === user.defaultCompanyId && c.isOwner === user.defaultCompanyIsOwner);
                        }

                        if (!company && companyList.length > 0) {
                            company = companyList[0];
                        }

                        if (company) {
                            selectCompany(company.id, company.isOwner);
                        }
                    }
                });
        });
    }, [getAccessTokenSilently, selectCompany, selectedCompanyId, isOwner]);

    return (
        <ApplicationContext.Provider
            value={{
                navIsVisible,
                toggleNav,
                companies,
                selectedCompanyId,
                isOwner,
                selectCompany,
                currentUser,
                location,
            }}
        >
            {children}
        </ApplicationContext.Provider>
    );
};

const ApplicationProvider = Provider;

export { ApplicationContext, ApplicationProvider, useApplicationContext };
