import { DayOfMonthOption, TimeOption } from './subscriptionTypes';

export const SUBSCRIPTION_TYPES: string[] = ['Dashboard', 'Report'];

export enum FrequencyOption {
    DAILY,
    WEEKLY,
    MONTHLY,
}

export enum TimeZoneOption {
    EST,
    CST,
    MST,
    PST,
}

export enum DayOption {
    SUNDAY,
    MONDAY,
    TUESDAY,
    WEDNESDAY,
    THURSDAY,
    FRIDAY,
    SATURDAY,
}

export const getDayFromOffset = (day: DayOption, offset: number): DayOption => {
    return (day + offset) % 7;
};

export const DAY_OF_MONTH_OPTIONS: DayOfMonthOption[] = [
    { value: '1', label: '1st' },
    { value: '2', label: '2nd' },
    { value: '3', label: '3rd' },
    { value: '4', label: '4th' },
    { value: '5', label: '5th' },
    { value: '6', label: '6th' },
    { value: '7', label: '7th' },
    { value: '8', label: '8th' },
    { value: '9', label: '9th' },
    { value: '10', label: '10th' },
    { value: '11', label: '11th' },
    { value: '12', label: '12th' },
    { value: '13', label: '13th' },
    { value: '14', label: '14th' },
    { value: '15', label: '15th' },
    { value: '16', label: '16th' },
    { value: '17', label: '17th' },
    { value: '18', label: '18th' },
    { value: '19', label: '19th' },
    { value: '20', label: '20th' },
    { value: '21', label: '21st' },
    { value: '22', label: '22nd' },
    { value: '23', label: '23rd' },
    { value: '24', label: '24th' },
    { value: '25', label: '25th' },
    { value: '26', label: '26th' },
    { value: '27', label: '27th' },
    { value: '28', label: '28th' },
    { value: 'Last', label: 'Last' },
];

export const TIME_OPTIONS: TimeOption[] = [
    { value: 7, label: '7:00 AM' },
    { value: 12, label: '12:00 PM' },
    { value: 17, label: '5:00 PM' },
];
