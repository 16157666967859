import { Avatar, Menu, MenuItem } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import { useApplicationContext } from '../../ApplicationContext';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import './UserMenu.css';

const UserMenu = () => {
    const { currentUser } = useApplicationContext();
    const { logout, user } = useAuth0();
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const [userInitials, setUserInitials] = useState(undefined);

    const onLogout = () => {
        sessionStorage.clear();
        logout({ logoutParams: { returnTo: window.location.origin } });
    };

    useEffect(() => {
        if (currentUser?.firstName?.length > 0 && currentUser?.lastName?.length > 0) {
            setUserInitials(`${currentUser.firstName.charAt(0)}${currentUser.lastName.charAt(0)}`.toUpperCase());
        } else if (user) {
            let initials = '';
            if (user.given_name?.length > 0 && user.family_name?.length > 0) {
                initials = `${user.given_name.charAt(0)}${user.family_name.charAt(0)}`;
            } else if (user.name?.length > 0) {
                let names = user.name.split(' ');
                for (let i = 0; i < 2; i++) {
                    if (names[i] !== undefined) {
                        initials += names[i].charAt(0);
                    }
                }
            } else {
                initials = user.email.charAt(0);
            }
            setUserInitials(initials.toUpperCase());
        }
    }, [user, setUserInitials, currentUser?.firstName, currentUser?.lastName]);

    return (
        <>
            <Avatar
                id="user-avatar"
                aria-controls={open ? 'user-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                {userInitials}
            </Avatar>
            <Menu
                disableAutoFocusItem
                id="user-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'user-avatar',
                }}
            >
                <MenuItem>
                    <a href="mailto:support@seniorlytics.com">
                        <Grid container alignItems={'center'}>
                            <Grid container>
                                <EmailOutlinedIcon className={'user-menu__icon'} fontSize={'small'} />
                            </Grid>
                            <Grid container>Contact Support</Grid>
                        </Grid>
                    </a>
                </MenuItem>
                <MenuItem onClick={onLogout}>
                    <Grid container alignItems={'center'}>
                        <Grid container>
                            <LogoutRoundedIcon className={'user-menu__icon'} fontSize={'small'} />
                        </Grid>
                        <Grid container>Logout</Grid>
                    </Grid>
                </MenuItem>
            </Menu>
        </>
    );
};

export default UserMenu;
