import { useApplicationContext } from '../ApplicationContext';
import { IconButton } from '@mui/material';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';

export default function ToggleSideMenuButton() {
    const { toggleNav } = useApplicationContext();

    return (
        <>
            <IconButton className="toggle-sidebar-btn" onClick={toggleNav}>
                <MenuRoundedIcon />
            </IconButton>
        </>
    );
}
