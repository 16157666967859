import React, { useCallback, useEffect, useState } from 'react';
import { Button } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import UserEditSubscriptionModal from './UserEditSubscriptionModal';
import { EditSubscriptionButtonProps } from './subscriptionTypes';
import { useApplicationContext } from '../../ApplicationContext';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';

const EditSubscriptionButton = ({ ButtonComponent, reportId, isDashboard, refreshCallback = () => {} }: EditSubscriptionButtonProps) => {
    const { getAccessTokenSilently } = useAuth0();
    const { selectedCompanyId } = useApplicationContext();

    const [showDialog, setShowDialog] = useState(false);
    const [isSubscribed, setIsSubscribed] = useState(false);
    const [currentSubscription, setCurrentSubscription] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [report, setReport] = useState({});

    const getCurrentSubscription = useCallback(
        (token: string) => {
            return axios
                .get(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Subscriptions/${reportId}/GetUserSubscription`, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    params: { companyId: selectedCompanyId },
                })
                .then((subscription) => {
                    setCurrentSubscription(subscription.data);
                });
        },
        [reportId, selectedCompanyId],
    );

    useEffect(() => {
        setIsLoading(true);

        getAccessTokenSilently()
            .then((token) => {
                if (reportId) {
                    return Promise.all([
                        axios
                            .get(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Subscriptions/${reportId}/IsSubscribed`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                                params: { companyId: selectedCompanyId },
                            })
                            .then((response) => {
                                setIsSubscribed(response.data);
                                if (response.data) {
                                    return getCurrentSubscription(token);
                                } else {
                                    setCurrentSubscription(null);
                                }
                            })
                            .catch(console.log),
                        axios
                            .get(`${process.env.REACT_APP_SENIORLYTICS_API}/api/Reports/${reportId}`, {
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                },
                                params: { companyId: selectedCompanyId },
                            })
                            .then((response) => {
                                setReport(response.data);
                            }),
                    ]);
                }
            })
            .finally(() => setIsLoading(false));
    }, [getAccessTokenSilently, getCurrentSubscription, reportId, selectedCompanyId]);

    return (
        <div>
            {ButtonComponent ? (
                <ButtonComponent disabled={isLoading} isSubscribed={isSubscribed} onClick={() => setShowDialog(true)} />
            ) : (
                <Button
                    disabled={isLoading}
                    size={'large'}
                    variant={'contained'}
                    color={'primary'}
                    startIcon={<EmailRoundedIcon />}
                    onClick={() => setShowDialog(true)}
                    style={{ marginRight: '10px' }}
                >
                    {!isSubscribed ? 'Subscribe' : 'Edit Subscription'}
                </Button>
            )}
            <UserEditSubscriptionModal
                reportId={reportId}
                report={report}
                isDashboard={isDashboard}
                currentSubscription={currentSubscription}
                getCurrentSubscription={getCurrentSubscription}
                setIsSubscribed={setIsSubscribed}
                showDialog={showDialog}
                setShowDialog={setShowDialog}
                refreshCallback={refreshCallback}
            />
        </div>
    );
};

export default EditSubscriptionButton;
