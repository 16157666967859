import { Auth0Provider } from '@auth0/auth0-react';
import { Outlet, useNavigate } from 'react-router-dom';
import { ApplicationProvider } from '../ApplicationContext';

export default function Auth0ProviderWithHistory() {
    const navigate = useNavigate();

    const onRedirectCallback = (appState) => {
        navigate(appState?.returnTo || window.location.pathname, { replace: true });
    };

    return (
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_DOMAIN}
            clientId={process.env.REACT_APP_AUTH0_ID}
            onRedirectCallback={onRedirectCallback}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: 'https://seniorlytics.api',
                useRefreshTokens: true,
            }}
        >
            <ApplicationProvider>
                <Outlet />
            </ApplicationProvider>
        </Auth0Provider>
    );
}
