import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { green, red } from '@mui/material/colors';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';
import { LicenseInfo } from '@mui/x-license';
import { ConfirmProvider } from 'material-ui-confirm';
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { reactPlugin } from './ApplicationInsightsService';
import reportWebVitals from './reportWebVitals';
import { SnackbarProvider } from 'notistack';
import './index.css';
import './Layout.css';

LicenseInfo.setLicenseKey('af32a56ef5c8c7e286377f6ded7c7563Tz05MjI5NyxFPTE3NDk3NjU1MTUwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=');

let theme = createTheme({
    palette: {
        primary: {
            main: '#3B82F6',
        },
        success: {
            main: green[500],
            light: green[100],
            contrastText: '#fff',
        },
        error: {
            main: red[800],
        },
    },
    components: {
        MuiMenuItem: {
            styleOverrides: {
                root: {
                    '& a': {
                        textDecoration: 'none',
                        color: 'inherit',
                    },
                },
            },
        },
        MuiDrawer: {
            styleOverrides: {
                paper: {
                    width: '17rem',
                },
            },
        },
        MuiGrid2: {
            styleOverrides: {
                root: {
                    '&.stacked-table__header': {
                        padding: '1rem',
                        borderBottom: '1px solid #dee2e6',
                    },
                    '&.stacked-table__row': {
                        borderBottom: '1px solid #dee2e6',
                        cursor: 'pointer',
                        '&:hover': {
                            background: '#e9ecef',
                            color: '#495057',
                        },
                    },
                    '&.stacked-table__cell': {
                        padding: '1rem',
                    },
                    '&.stacked-table__cell--label': {
                        fontWeight: 'bold',
                        fontSize: '0.875rem',
                        color: '#495057',
                        flex: '0 0 auto',
                        marginRight: '1rem',
                    },
                    '&.stacked-table__cell--value': {
                        textAlign: 'right',
                        fontSize: '0.875rem',
                        color: '#495057',
                        flex: '0 1 auto',
                    },
                },
            },
        },
        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontSize: '1.1rem',
                    fontWeight: 'bold',
                },
            },
        },
    },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
    <React.StrictMode>
        <AppInsightsContext.Provider value={reactPlugin}>
            <ThemeProvider theme={theme}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <ConfirmProvider>
                        <SnackbarProvider
                            maxSnack={6}
                            autoHideDuration={4000}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                        >
                            <App />
                        </SnackbarProvider>
                    </ConfirmProvider>
                </LocalizationProvider>
            </ThemeProvider>
        </AppInsightsContext.Provider>
    </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
