import Grid from '@mui/material/Unstable_Grid2';
import { MenuItem, Select, Stack } from '@mui/material';
import React from 'react';
import { TIME_OPTIONS, TimeZoneOption } from '../../Subscriptions/subscriptionConstants';

const SubscriptionDeliveryTime = ({ isLoading, time, setTime, timeZone, setTimeZone }) => {
    return (
        <Grid>
            <h5 className={'modal__subheader'}>Delivery Time</h5>
            <Stack direction={'row'} spacing={2}>
                <Select
                    size={'small'}
                    id={'subscription-time-dropdown'}
                    disabled={isLoading}
                    value={time}
                    onChange={(e) => setTime(e.target.value as number)}
                >
                    {TIME_OPTIONS.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                            {option.label}
                        </MenuItem>
                    ))}
                </Select>
                <Select
                    disabled={isLoading}
                    size={'small'}
                    id={'subscription-time-zone-dropdown'}
                    value={TimeZoneOption[timeZone]}
                    onChange={(e) => setTimeZone(TimeZoneOption[e.target.value as string])}
                >
                    {Object.keys(TimeZoneOption)
                        .filter((o) => isNaN(Number(o)))
                        .map((option) => (
                            <MenuItem key={option} value={option}>
                                {option}
                            </MenuItem>
                        ))}
                </Select>
            </Stack>
        </Grid>
    );
};

export default SubscriptionDeliveryTime;
